import { ComponentPropsWithoutRef, useLayoutEffect } from 'react';

import classNames from 'classnames';

import { useId } from '@floating-ui/react';

import { useDrawer } from './Drawer';

type DrawerTitleProps = ComponentPropsWithoutRef<'h2'>;

const DrawerTitle = ({ className, children, ...props }: DrawerTitleProps) => {
  const { setTitleId } = useDrawer();
  const id = useId();

  useLayoutEffect(() => {
    setTitleId(id);

    return () => {
      setTitleId(undefined);
    };
  }, [id, setTitleId]);

  return (
    <h2 className={classNames('drawer-title', className)} {...props}>
      {children}
    </h2>
  );
};

export default DrawerTitle;
