import { parseAsStringEnum, useQueryState } from 'nuqs';

export const SEARCH_PARAM_MODAL = {
  KARMA_LEADERBOARD: 'karma-leaderboard',
  KARMA_HISTORY: 'karma-history',
} as const;

const useSearchParamModal = () => {
  return useQueryState(
    'modal',
    parseAsStringEnum<(typeof SEARCH_PARAM_MODAL)[keyof typeof SEARCH_PARAM_MODAL]>(
      Object.values(SEARCH_PARAM_MODAL),
    ),
  );
};

export default useSearchParamModal;
