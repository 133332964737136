import React, { ComponentPropsWithoutRef } from 'react';

import classNames from 'classnames';

import Close from '@uikit/icons/Close';

import { useDrawer } from './Drawer';

type DrawerCloseButtonProps = ComponentPropsWithoutRef<'button'>;

const DrawerCloseButton = ({ className, ...props }: DrawerCloseButtonProps) => {
  const { close } = useDrawer();

  return (
    <button
      type="button"
      className={classNames('drawer-close-button', className)}
      {...props}
      onClick={close}
    >
      <Close className="icon-[0.5rem]" />
    </button>
  );
};

export default DrawerCloseButton;
