import React, { ComponentPropsWithoutRef, ReactNode } from 'react';

import classNames from 'classnames';

import DrawerCloseButton from './DrawerCloseButton';
import DrawerTitle from './DrawerTitle';

interface DrawerHeaderProps extends Omit<ComponentPropsWithoutRef<'header'>, 'title'> {
  title?: ReactNode;
}

const DrawerHeader = ({ title, className }: DrawerHeaderProps) => {
  return (
    <header className={classNames('drawer-header', className)}>
      <DrawerTitle>{title}</DrawerTitle>

      <DrawerCloseButton />
    </header>
  );
};

export default DrawerHeader;
