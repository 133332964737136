import { LinkProps } from 'next/link';
import { usePathname } from 'next/navigation';

export const isPathnameLinkHref = (pathname: string, url: LinkProps['href']) => {
  return typeof url === 'string' ? url === pathname : url.pathname === pathname;
};

export const useIsPathnameLinkHref = (url: LinkProps['href']) => {
  const pathname = usePathname();

  return isPathnameLinkHref(pathname, url);
};
