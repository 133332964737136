import React, {
  ComponentPropsWithoutRef,
  ComponentType,
  ElementType,
  forwardRef,
  MouseEvent,
  ReactNode,
  Ref,
} from 'react';

import classNames from 'classnames';

import { useDrawer } from './Drawer';

interface DrawerMenuItemGeneralProps {
  Icon?: ComponentType;
  label?: string;
  className?: string;
  children?: ReactNode;
  onClick?: (e: MouseEvent<HTMLElement>) => void;
}

export type DrawerMenuItemProps<C extends ElementType> = DrawerMenuItemGeneralProps & {
  Component: C;
} & ComponentPropsWithoutRef<C>;

const DrawerMenuItem = <C extends ElementType>(
  { Component, className, children, label, Icon, onClick, ...props }: DrawerMenuItemProps<C>,
  ref: Ref<ElementType<C>>,
) => {
  const { close } = useDrawer();

  const handleClick = (e: MouseEvent<HTMLElement>) => {
    if (onClick) {
      onClick(e);
    }

    close();
  };

  return (
    <Component
      className={classNames('drawer-menu-item', className)}
      onClick={handleClick}
      ref={ref}
      // TODO: fix type
      {...(props as any)}
    >
      {Icon && (
        <span className="icon-lg mr-4">
          <Icon />
        </span>
      )}
      {label}
      {children}
    </Component>
  );
};

export default forwardRef(DrawerMenuItem) as <C extends ElementType>(
  props: DrawerMenuItemProps<C>,
  ref: Ref<ElementType<C>>,
) => ReturnType<typeof DrawerMenuItem>;
